import request from '@/utils/request'


export const getsurvey = () => {
  return  request({
    method: 'get',
    url: '/getsurvey',
  })
}

export const submitSurvey = (data) => {
  return  request({
    method: 'post',
    url: '/submitSurvey',
    data:data
  })
}

export const isAdmin = data => {
  return  request({
    method: 'get',
    url: '/isAdmin',
    data:data
  })
}

export const getSurveyDetails = data => {
  return  request({
    method: 'get',
    url: '/getSurveyDetails?surveyId=' + data,
  })
}