<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',

}
  var ua = navigator.userAgent.toLowerCase();
  // var isWeixin = ua.indexOf('micromessenger') != -1;
  // if (!isWeixin) {
  //     window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=888"
  // }
</script>
<style>
#app {
  margin: 0;
  padding: 0;
}
</style>
