<template>
  <div>
    <van-nav-bar title="个人中心" />
    <van-cell-group inset>
      <van-field label="姓名" v-model="name" readonly />
      <van-field label="班级" v-model="classes" readonly />
      <van-cell title="修改密码" is-link @click="changePassword" />
    </van-cell-group>
    <div style="margin: 16px">
      <van-button round block type="primary" @click="loginOut">
        退出
      </van-button>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item name="list" replace to="/list" icon="records-o"
        >问卷列表</van-tabbar-item
      >
      <van-tabbar-item name="home" replace to="/home" icon="home-o"
        >个人中心</van-tabbar-item
      >
    </van-tabbar>
    <van-popup
      v-model="show"
      :style="{ width: '80%', padding: '10px', borderRadius: '15px' }"
      @submit="changeed"
    >
      <van-form>
        <van-field
        v-model="form.oldPassword"
        name="原密码"
        label="原密码"
        placeholder="原密码"
        :rules="[{ required: true, message: '请填写原密码' }]"
      />
      <van-field
        v-model="form.newPassword"
        type="password"
        name="新密码"
        label="新密码"
        placeholder="新密码"
        :rules="[{ required: true, message: '请填写新密码' },]"
      />
      <van-field
        v-model="form.confirmPassword"
        type="password"
        name="确认密码"
        label="确认密码"
        placeholder="确认密码"
        :rules="[{ required: true, message: '请确认新密码' }]"
      />
      <van-button round block type="primary" native-type="submit" @click="changeed">
        确定
      </van-button>
      </van-form>
    </van-popup>
  </div>
</template>
<script>
import { userInfo, logout,changePwd } from "../../api/homePage";
import { Toast } from 'vant';
export default {
  data() {
    return {
      active: "home",
      name: "",
      classes: "",
      show: false,
      form: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      userInfo().then((res) => {
        if (res.data.code == 200) {
          this.name = res.data.data.studentName;
          this.classes = res.data.data.className;
        }
      });
    },
    changeed(){
      changePwd(this.form).then((result) => {
        console.log(result)
        if(result.data.code == 500) {
          Toast.fail(result.data.msg);
        }
        if(result.data.code == 200) {
          Toast.success(result.data.data);
          this. loginOut()
        }
      })
  },
  validator(val){
    if(val != this.form.newPassword){
      return false;
    }else{
      return true;
    }
  },
    changePassword() {
      this.form = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      }
      this.show = true;
    },
    loginOut() {
      
      localStorage.removeItem("token");
        this.$router.push("/login");
      // logout().then((res) => { 
    },
  },
};
</script>
<style></style>
