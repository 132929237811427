<template>
  <div class="container">
    <van-nav-bar title="问卷列表" />
    <van-row>
      <van-col
        span="24"
        v-for="(item, index) in surveyList"
        :class="
          item.surveyRecord.edit == 1
            ? 'propertyJump '
            : 'propertyJump '
        "
        :key="item.surveyRecord.surveyRecordId"
      >
        <i
          v-show="showIcon"
          :class="
            item.surveyRecord.edit == 2
              ? 'iconfont icon-yijieshu iconStyle '
              : item.surveyRecord.edit == 1
              ? 'iconfont icon-jinhangzhong iconStyle'
              : 'iconfont icon-yiwancheng iconStyle'
          "
        />
        <van-cell
          :title="item.surveyRecord.surveyName"
          :value="'结束时间:' + item.surveyRecord.surveyEndDate"
          @click="jumpToDetail(item, index)"
        >
        </van-cell>
      </van-col>
    </van-row>
    <van-tabbar v-model="active">
      <van-tabbar-item name="list" replace to="/list" icon="records-o"
        >问卷列表</van-tabbar-item
      >
      <van-tabbar-item name="home" replace to="/home" icon="home-o"
        >个人中心</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
import { getsurvey , isAdmin } from "@/api/questionsUpdate";
export default {
  data() {
    return {
      active: "list",
      surveyList: [],
      showIcon:true
    };
  },
  created() {
    this.getList();
    this.checkStatus();
  },
  methods: {
    checkStatus() {
      isAdmin().then( res=> {
        if(res.data.data){
          this.showIcon = false
        }else{
          this.showIcon = true
        }
      })
    },
    getList() {
      getsurvey().then((res) => {
        this.surveyList = res.data.data;
      });
    },
    jumpToDetail(res) {
      // if (res.surveyRecord.finished == 0 || res.surveyRecord.edit == 0) {
      if ( this.showIcon) {
        this.$router.push({
          name: "questionsList",
          params: {
            item: res,
          },
        });
      }else{
        this.$router.push({
          name: "questionlist",
          params: {
            item: res,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  margin: 0;
}
.propertyJump {
  margin-top: 8px;
  padding: 0 6px;
  position: relative;
}
.disabledProperty {
  cursor: no-drop;
  pointer-events: none;
  opacity: 0.6;
}
.iconStyle {
  position: absolute;
  z-index: 9;
  font-size: 40px;
  right: 40%;
  top:15%;
}
.icon-yijieshu{
  color: red;
}
.icon-jinhangzhong{
  color: green;
}
.icon-yiwancheng{
  color: blue;
}
</style>
