/**
 * 请求模块
 */
import axios from "axios";
import store from "@/store";

const request = axios.create({
  baseURL: "https://manyidu.sunsivi.com/api/", // 接口的基准路径
  // baseURL: "https://manyidu.sunsivi.com:18765/", // 接口的基准路径
  timeout: 20000, // 请求超时时间
});

// 请求拦截器
// Add a request interceptor
request.interceptors.request.use(
  function (config) {
    // 请求发起会经过这里
    // config：本次请求的请求配置对象
    const { user } = store.state;
    if (user && user.data.token) {
      const token = localStorage.getItem("token");
      if (token) config.headers["satoken"] = token;
    }
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");
      if (token) config.headers["satoken"] = token;
    }

    // 注意：这里务必要返回 config 配置对象，否则请求就停在这里出不去了
    return config;
  },
  function (error) {
    // 如果请求出错了（还没有发出去）会进入这里
    return Promise.reject(error);
  }
);

// 响应拦截器

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  if ( response.data ) {
    let data = response.data
    if (data) {
        response.data = data
    }
}

  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default request;
