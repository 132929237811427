<template>
  <div class="container">
    <van-nav-bar left-arrow @click-left="goBack" :title="title" />
    <div v-show="show" class="headerList" style="color: #909399">
      <span>班级名称</span>
      <span>总人数</span>
      <span>完成人数</span>
      <span>未完成人数</span>
    </div>
    <div class="headerList" v-for="(item, index) in list" :key="index">
      <span>{{ item.className }}</span>
      <span>{{ item.total }}</span>
      <span>{{ item.finished }}</span>
      <span>{{ item.unfinished }}</span>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getSurveyRecord } from "../../api/surveyRecord";
export default {
  data() {
    return {
      title: "问卷填写详情",
      list: [],
      show: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    goBack() {
      this.$router.push("/list");
    },
    getList() {
      if (this.$route.params.item == undefined) {
        this.goBack();
      } else {
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "加载中...",
          loadingType: "spinner",
        });
        let data = this.$route.params.item;
        getSurveyRecord(data.surveyRecord.surveyRecordId).then((res) => {
          this.list = res.data.data;
          this.show = true;
          Toast.clear();
        });
      }
    },
  },
};
</script>
<style scoped>
.headerList {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.headerList span {
  width: 25%;
  text-align: center;
}
</style>
