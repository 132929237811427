var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('van-nav-bar',{attrs:{"title":"问卷列表"}}),_c('van-row',_vm._l((_vm.surveyList),function(item,index){return _c('van-col',{key:item.surveyRecord.surveyRecordId,class:item.surveyRecord.edit == 1
          ? 'propertyJump '
          : 'propertyJump ',attrs:{"span":"24"}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIcon),expression:"showIcon"}],class:item.surveyRecord.edit == 2
            ? 'iconfont icon-yijieshu iconStyle '
            : item.surveyRecord.edit == 1
            ? 'iconfont icon-jinhangzhong iconStyle'
            : 'iconfont icon-yiwancheng iconStyle'}),_c('van-cell',{attrs:{"title":item.surveyRecord.surveyName,"value":'结束时间:' + item.surveyRecord.surveyEndDate},on:{"click":function($event){return _vm.jumpToDetail(item, index)}}})],1)}),1),_c('van-tabbar',{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tabbar-item',{attrs:{"name":"list","replace":"","to":"/list","icon":"records-o"}},[_vm._v("问卷列表")]),_c('van-tabbar-item',{attrs:{"name":"home","replace":"","to":"/home","icon":"home-o"}},[_vm._v("个人中心")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }