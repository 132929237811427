import request from '@/utils/request'

export const userInfo = () => {
  return  request({
    method: 'get',
    url: '/getUserInfo',
  })
}

export const getDetail = (data) => {
  return  request({
    method: 'get',
    url: '/unfinishedSurvey',
    data:data
  })
}

export const logout = () => {
  return  request({
    method: 'post',
    url: '/logout',
  })
}

export const changePwd = (parma) => {
  return  request({
    method: 'post',
    url: '/changePassword',
    data: parma
  })
}

