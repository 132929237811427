<template>
  <div class="container">
    <div class="loginBox">
      <p style="text-align: center">南京晓庄实幼(机关二幼)<br/>家长满意率调查</p>
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="loginForm.loginName"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="loginForm.password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
        
        </van-cell-group>
        <div style="margin: 16px">
          <van-button round block type="primary" native-type="submit">
            登录
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/login";
import { Toast } from 'vant';
export default {
  data() {
    return {
      loginForm: {
        loginName: "",
        password: "",
      },
      checked:'',
    };
  },
  methods: {
    onSubmit() {
      login(this.loginForm).then((res) => {
        if (res.data.code == 200) {
          Toast.success('登陆成功');
          localStorage.setItem("token", res.data.data);
          this.$router.push("/list");
        }else if (res.data.code == 500) {
        Toast.fail(res.data.msg);
        }
      });
    },
  },
};
</script>
<style scoped>
.container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
}
.loginBox {
  background-color: #ffffff;
  width: 80%;
  border-radius: 10px;
  padding: 15px;
}
</style>
