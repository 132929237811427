//引入vue
import Vue from 'vue';
//引入vue-router
import VueRouter from 'vue-router'
//第三方库需要use一下才能用
Vue.use(VueRouter)

import login from '../views/login/loginPage.vue'
import questionsList from '../views/question/questionList.vue'
import list from '../views/list/listPage.vue'
import home from '../views/myPage/homePage.vue'
import questionlist from '../views/listssss/list.vue'

//定义routes路由的集合，数组类型
const routes = [
  {
    path: '/',
    name: 'login',
    component: login,
  },

  {
    path: '/home',
    name: 'home',
    component: home,
  },

  {
    path: '/login',
    name: 'login',
    component: login
  },

  {
    path: '/questionlist',
    name: 'questionlist',
    component: questionlist
  },

  {
    path: '/questionsList',
    name: 'questionsList',
    component: questionsList
  },

  {
    path: '/list',
    name: 'list',
    props: true,
    component: list
  },

]

//实例化VueRouter并将routes添加进去
const router = new VueRouter({
  //ES6简写，等于routes：routes
  routes
});

//全局前置路由守卫————初始化的时候被调用、每次路由切换之前被调用
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token');
  //如果路由需要跳转
  if (to.name !== 'login' && !token) {
    //如果要跳转的页面不是登录页面 并且没有token就直接跳转登录页
    next({ name: 'login' })
  } else if(to.name === 'login' && token){
    next({ name: 'list'})
  } else{
      next();
  }
})

//抛出这个这个实例对象方便外部读取以及访问
export default router